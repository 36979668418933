import { InterestRate } from "@canopyinc/api-docs/types/ts/InterestRate.type";
import { LineItem } from "@canopyinc/api-docs/types/ts/LineItem.type";
import { Loan } from "@canopyinc/api-docs/types/ts/Loan.type";
import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";
import { parseISO } from "date-fns";
import Decimal from "decimal.js-light";
import { sortByEffectiveAt } from "./time";

export const isLoan = (loan: LineItem) => loan.line_item_overview?.line_item_type === "LOAN";
export const isValidLoan = (loan: LineItem) => isLoan(loan) && loan.line_item_overview?.line_item_status === "VALID";
export const isPaidOffLoan = (loan: LineItem) => isValidLoan(loan) && loan.line_item_summary?.principal_cents === 0;

export const isRestructuredLoan = (loan: LineItem) =>
  isLoan(loan) && loan.line_item_overview?.restructure_to_line_item_id != null;
export const isActiveLoan = (loan: LineItem) => isLoan(loan) && !isPaidOffLoan(loan) && !isRestructuredLoan(loan);
export const isInactiveLoan = (loan: LineItem) => isLoan(loan) && (isPaidOffLoan(loan) || isRestructuredLoan(loan));

// V2 utils
export const isRestructuredLoanV2 = (loan: Loan) => loan.restructured_to_line_item_id != null;
export const isResultOfRestructureV2 = (loan: Loan) =>
  loan.restructured_from_line_item_ids && loan.restructured_from_line_item_ids.length > 0;
export const isPaidOffLoanV2 = (loan: Loan) => loan.loan_summary?.loan_paid_off_date != null;
export const isActiveLoanV2 = (loan: Loan) => !isPaidOffLoanV2(loan);

export const isLoanTransaction = (transaction: Transaction) => transaction.transaction_type === "LOAN";

export const getLoanPercentagePaid = (loan: Loan) => {
  if (loan?.restructured_to_line_item_id) return 100;

  const totalRepaymentCents = loan.loan_summary.total_repayment_cents;
  const totalPaidCents = Math.abs(
    new Decimal(loan.loan_summary.total_principal_paid_to_date_cents)
      .plus(loan.loan_summary.total_interest_paid_to_date_cents)
      .plus(loan.loan_summary.total_fees_paid_to_date_cents)
      .toNumber()
  );
  return totalRepaymentCents === 0
    ? 0
    : new Decimal(totalPaidCents).dividedBy(totalRepaymentCents).times(100).toNumber();
};

export const getLoanCurrentInterestPolicy = (loan: Loan, currentDateTime: Date) => {
  const currentInterestPolicy = loan.loan_terms?.interest_policies?.find(
    (rate) =>
      rate.interest_effective_inclusive_start &&
      rate.interest_effective_exclusive_end &&
      parseISO(rate.interest_effective_inclusive_start) <= currentDateTime &&
      currentDateTime < parseISO(rate.interest_effective_exclusive_end)
  );

  return currentInterestPolicy;
};

export const getLoanCurrentInterestRate = (
  loan: Loan,
  currentDateTime: Date,
  defaultInterestRate: number | undefined = undefined
) => {
  const currentInterestPolicy = getLoanCurrentInterestPolicy(loan, currentDateTime);

  return currentInterestPolicy?.interest_percent
    ? new Decimal(currentInterestPolicy.interest_percent).dividedBy(100).toNumber()
    : defaultInterestRate;
};

export const getLoanTotalInterestAndFeesCents = (loan: Loan) => {
  return new Decimal(loan.loan_summary.interest_balance_cents)
    .plus(Math.abs(loan.loan_summary.total_interest_paid_to_date_cents))
    .plus(loan.loan_summary.fee_balance_cents)
    .plus(Math.abs(loan.loan_summary.total_fees_paid_to_date_cents))
    .toNumber();
};

export const getOrderedInterestRatesFromLoanPolicies = (loan: Loan) => {
  const sortedRates =
    loan.loan_terms?.interest_policies?.sort((a, b) => {
      return sortByEffectiveAt.ascending(
        {
          effective_at: a.interest_effective_inclusive_start,
        },
        {
          effective_at: b.interest_effective_inclusive_start,
        }
      );
    }) ?? [];

  const loanInterestPolicies: InterestRate[] = sortedRates.map((rate) => {
    return {
      account_id: loan.account_id,
      effective_at_exclusive_end: rate.interest_effective_exclusive_end,
      effective_at_inclusive_start: rate.interest_effective_inclusive_start,
      interest_rate: rate.interest_percent,
      line_item_id: loan.line_item_id,
    };
  });

  return loanInterestPolicies;
};
